import React, { useState, createContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { apiConfig } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { Patient, Vaccine } from 'src/types/patient';
import { TIME_UNIT_BY_DAYS } from 'src/utils/timeUnitByDays';
import { useLocation } from 'react-router';

interface GetPatientDataByAccessCodeProps {
  accessCode: string;
  setLoading: boolean;
};
interface PatientContextData {
  patient: Patient | null;
  patientAccessCode: string;
  isLoadingPatientVaccines: boolean;
  isUpdatingPatientVaccines: boolean;
  setIsUpdatingPatientVaccines: React.Dispatch<React.SetStateAction<boolean>>;
  sortVaccines: (a: Vaccine, b: Vaccine) => number;
  getPatientDataByAccessCode: ({ accessCode, setLoading }: GetPatientDataByAccessCodeProps) => Promise<void>;
};

interface PatientProviderProps {
  children: React.ReactNode;
};

const PatientContext = createContext({} as PatientContextData);

const PatientProvider = ({ children }: PatientProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [isLoadingPatientVaccines, setIsLoadingPatientVaccines] = useState(false);
  const [isUpdatingPatientVaccines, setIsUpdatingPatientVaccines] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [patientAccessCode, setPatientAccessCode] = useState('');

  const sortVaccines = (a: Vaccine, b: Vaccine) => {
    try {
      return (
        a.applicationStart *
        TIME_UNIT_BY_DAYS[a.applicationStartUnit] -
        b.applicationStart *
        TIME_UNIT_BY_DAYS[b.applicationStartUnit]
      );
    } catch (_) {
      return 0;
    }
  };

  const getPatientDataByAccessCode = async ({
    accessCode,
    setLoading,
  }: GetPatientDataByAccessCodeProps) => {
    try {
      if (setLoading) {
        setIsLoadingPatientVaccines(true);
      }

      if (accessCode.length !== 6) {
        throw new Error('O código deve conter 6 dígitos numéricos')
      };

      const { data: response } = await axiosInstance
        .get(`${apiConfig.coreApiBaseUrl}/hcps/user?code=${accessCode}`);

      setPatient(response);
      setPatientAccessCode(accessCode);
    } catch (err) {
      enqueueSnackbar(`${err}`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });

      setPatient(null);
      console.error(err);
    } finally {
      if (setLoading) {
        setIsLoadingPatientVaccines(false);
      }
    }
  };

  useEffect(() => {
    if (!location) {
      return;
    }

    setPatient(null);
  }, [location]);

  return (
    <PatientContext.Provider value={{
      patient,
      patientAccessCode,
      isLoadingPatientVaccines,
      isUpdatingPatientVaccines,
      setIsUpdatingPatientVaccines,
      getPatientDataByAccessCode,
      sortVaccines,
    }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export {
  PatientContext,
  PatientProvider,
};