import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import ApprovedGuard from 'src/components/ApprovedGuard';
import GuestGuard from './components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },
  {
    exact: true,
    path: '/register',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },
  {
    exact: true,
    path: '/forgot-password',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/ForgotPasswordView')),
  },
  {
    exact: false,
    path: '/confirm-new-password',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/ConfirmPasswordView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/home')),
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        guard: ApprovedGuard,
        routes: [
          {
            exact: true,
            path: '/app/add-user',
            component: lazy(() => import('src/views/add-user')),
          },
          {
            exact: true,
            path: '/app/edit-profile',
            component: lazy(() => import('src/views/edit-profile')),
          },
          {
            exact: true,
            path: '/app/apply-vaccine',
            component: lazy(() => import('src/views/apply-vaccine')),
          },
          {
            exact: true,
            path: '/app/transcribe-registry',
            component: lazy(() => import('src/views/transcribe-registry')),
          },
          {
            exact: true,
            path: '/app/digital-certificates',
            component: lazy(() => import('src/views/digital-certificates')),
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '*',
    component: () => <Redirect to="/app/home" />,
  },
];

export default routes;
