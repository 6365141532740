/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import type { Theme } from 'src/theme';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, Link, List, Typography, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';
import {
  FaRegIdCard,
  FaUserPlus,
  FaSyringe,
  // FaSlidersH,
  // FaChartBar,
  FaFileMedicalAlt,
  FaCertificate,
} from 'react-icons/fa';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}
interface PageProps {
  title: string;
  href?: string;
  icon?: ReactNode;
}

interface ReduceChildRoutesProps {
  acc: any[];
  page: PageProps;
}

// TODO: Redefinir essas rotas e remover codigo antigo, tbm remover o apexcharts
const pages: PageProps[] = [
  {
    title: 'NAVBAR_MY_PROFILE',
    icon: FaRegIdCard,
    href: '/app/edit-profile',
  },
  {
    title: 'NAVBAR_ADD_USER',
    icon: FaUserPlus,
    href: '/app/add-user',
  },
  {
    title: 'NAVBAR_APPLY_VACCINE',
    icon: FaSyringe,
    href: '/app/apply-vaccine',
  },
  {
    title: 'NAVBAR_TRANSCRIBE_DATA',
    icon: FaFileMedicalAlt,
    href: '/app/transcribe-registry',
  },
  {
    title: 'NAVBAR_DIGITAL_CERTIFICATES',
    icon: FaCertificate,
    href: '/app/digital-certificates',
  },
];

function renderNavItems(pages: PageProps[]) {
  return <List disablePadding>{pages.reduce((acc, page) => reduceChildRoutes({ acc, page }), [])}</List>;
}

function reduceChildRoutes({ acc, page }: ReduceChildRoutesProps) {
  const key = page.title;

  acc.push(<NavItem depth={0} href={page.href} icon={page.icon} key={key} title={page.title} />);

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoContainer: {
    background: theme.palette.background.logo,
    padding: '.6rem',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/home">
              <Logo backgroundImage={`url(${user.clinic?.logo?.url})`} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user.name + ' ' + user.surname}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>{renderNavItems(pages)}</Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              {t('NAVBAR_NEED_HELP_TEXT')}
            </Typography>
            <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
              {t('NAVBAR_CHECK_DOCS')}
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
